
































































/* board: Page Style */
.pagination-container{
    @at-root{
        [data-header-type="shop"] + .main .pagination-container{
            .pagination{
                 &--current { color: var(--v-primary-base); }
            }
        }
    }
    display: flex; justify-content: center; margin-top: 80px; margin-right: -2px; margin-left: -2px;
    .pagination{
        display: inline-flex; align-items: center; justify-content: center; position: relative; margin: 0 2px; width: 32px; height: 32px;
        text-align: center; font-size: 1.4rem; color: #bbb; font-weight: 400;
        border: 1px solid transparent; border-radius: 4px; cursor: pointer;
        svg { width: auto; height: 10px; fill: #6E6E6E; }
        &--current { z-index: 2; color: #222; font-weight: 700; }
        &--backward, &--forward,
        &--prev, &--next{
            border-color: #eee;
        }
    }
}
.pagination-container--block{
    .pagination.current { color: #ffffff; background-color: var(--v-primary-base); border-color: var(--v-primary-base); }
}
@media (min-width:1025px){
    .pagination-container{
        @at-root{
            [data-header-type="shop"] + .main .pagination-container{
                .pagination:not(.current):hover { border-color: var(--v-primary-base); }
                .pagination:not(.current):hover svg { fill: var(--v-primary-base); }
            }
        }
        .pagination:not(.current):hover { border-color: var(--v-primary-base); }
        .pagination:not(.current):hover svg { fill: var(--v-primary-base); }
    }
}
@media(max-width:1024px){
	.pagination-container{
        margin-top: 24px;
        .pagination { font-size: 14px; }
    }
}
@media (max-width:576px){
    .pagination-container{
        margin-top: 24px;
        .pagination {
            width:30px;
            height: 30px;
        }
    }
}
