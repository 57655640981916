































































	.v-expansion-panels {
        border-radius: 0;
        border-top: 1px solid var(--v-primary-base);
        border-bottom: 1px solid var(--border-color);
    }
	.v-expansion-panel:before { content: none; }
	.v-expansion-panel-header{
		.board-list--faq__category { flex: 0 0 90px; height: 25px; line-height: 23px; text-align: center; font-size: 14px; color: #999999; border: 1px solid #999999; border-radius: 3px; }
		.board-list--faq__icon { flex: 0 0 24px; position: relative; margin: 0 12px 0 24px; height: 24px; border: 2px solid var(--v-primary-base); border-radius: 50%; }
		.board-list--faq__icon::before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url('/images/icon/faq-q.svg') no-repeat center / auto 11px; }
		.board-list--faq__tit { line-height: 1.6; font-size: 18px; }
	}
	::v-deep .v-expansion-panel-content__wrap{
		display: flex; padding-top: 20px; background-color: #f9f9f9; border-top: 1px solid var(--border-color);
		.board-list--faq__icon { flex: 0 0 24px; position: relative; margin: 0 12px 0 114px; height: 24px; border: 2px solid #999999; border-radius: 50%; }
		.board-list--faq__icon::before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url('/images/icon/faq-a.svg') no-repeat center / auto 11px; }
	}
	::v-deep .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after { border-color: var(--border-color); }
    .v-expansion-panel--active>.v-expansion-panel-header{
        min-height: auto;
    }
	::v-deep .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon { font-size: 30px; }
	::v-deep .theme--light.v-expansion-panels .v-expansion-panel-header--active .v-expansion-panel-header__icon .v-icon { color: var(--v-primary-base); }
    @media(max-width:1200px){

    }
    @media(max-width:768px){
        .v-expansion-panel-header{
            flex-direction: column; align-items: flex-start; padding: 14px 34px 14px 14px;
            .board-list--faq__category { flex: 0 0 25px; width: 85px; font-size: 14px; }
            .board-list--faq__icon { display: none; }
            .board-list--faq__category + .board-list--faq__tit{
                margin-top: 10px;
            }
            .board-list--faq__tit {  line-height: 1.5; font-size: 14px; }
        }
        ::v-deep .v-expansion-panel-header__icon {
            position: absolute;
            top: 50%;
            right: 8px;
            -webkit-transform: translate(0,-50%); -ms-transform: translate(0,-50%); transform: translate(0,-50%);
            margin:0;
        }
        ::v-deep .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon { font-size: 24px; }
        ::v-deep .v-expansion-panel-content__wrap{
            .board-list--faq__icon { display: none; }
        }
        ::v-deep .v-expansion-panel-content__wrap{
            padding: 14px;
            .board-list--faq__txt { font-size: 14px; }
        }
    }
